import React from "react";
import { Row, Col } from "react-bootstrap";
import TransactionInvoice from "./TransactionInvoice";
import { useTranslation } from "react-i18next";

const PayModuleModal = ({ item }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{item.transactionId}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item?.customer?.firstName} ${item?.customer?.surName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.amount)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Bayqi Commission")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.commission)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{item.status}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Description")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{item.description}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TransactionInvoice item={item} />
        </Col>
      </Row>
    </div>
  );
};

export default PayModuleModal;
