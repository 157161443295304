import React, { useState } from "react";
import "./Transactions.scss";
import { Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AllTransactionsDetailsModal = ({ item, toggle }) => {
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState(item.status);
  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.transactionId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item?.customerDetail?.firstName} ${item?.customerDetail?.surName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.amount)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
          </span>
        </Col>
      </Row>
      {(item.transactionType == "PAY" ||
        item.transactionType == "QR_CATALOG_SCAN") && (
        <Row>
          <Col md={6}>
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
                fontSize: "1rem",
              }}
            >
              {t("Description")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {item.description}
            </span>
          </Col>
        </Row>
      )}
      {item.transactionType !== "PAY" && (
        <Row>
          <Col style={{ display: "flex", justifyContent: "left" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
                fontSize: "1rem",
              }}
            >
              {t("Ordered Products")}
            </span>
          </Col>
          {item.products?.map((prod, index) => {
            return (
              <Row key={index} style={{ justifyContent: "left" }}>
                <Col md={2}>
                  <img
                    alt={""}
                    style={{ marginRight: "10px" }}
                    src={prod.image}
                    width={67}
                    height={67}
                  />
                </Col>
                <Col md={6}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.75rem",
                          color: "black",
                        }}
                      >
                        {t("Name")}
                      </span>
                    </Col>
                    <Col>
                      <span style={{ color: "black" }}>{prod.name}</span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.75rem",
                          color: "black",
                        }}
                      >
                        {t("Quantity")}
                      </span>
                    </Col>
                    <Col>
                      <span style={{ color: "black" }}>{prod.qty}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default AllTransactionsDetailsModal;
